import React from 'react'
import { navigate } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import { Block, Text } from 'components/atoms'
import { PhoneStep } from 'components/organisms'
import get from 'lodash/get'
import { Header } from 'components/molecules'
import withProfile from 'hocs/withProfile'

const PhoneOtpPage = ({ profile, location }) => {
  const intl = useIntl()
  // console.log('location', location)
  const skipPage = get(location, 'state.skipPage')
  const windowGlobal = typeof window !== 'undefined' && window
  const { localStorage } = windowGlobal
  return (
    <Block position={'relative'}>
      <Header boxShadow />
      <PhoneStep profile={profile} />
      <Block textAlign={'center'}>
        <Text
          link
          mt={30}
          onClick={() => {
            if (localStorage.getItem('alreadyGoToPhonePage') !== 'alreadyGo') {
              localStorage.setItem('alreadyGoToPhonePage', 'alreadyGo')
              return navigate('/')
            }
            return navigate('/')
          }}
        >
          {skipPage
            ? `${intl.formatMessage({ id: 'later' })} >`
            : `< ${intl.formatMessage({ id: 'backToMainPage' })}`}
        </Text>
      </Block>
    </Block>
  )
}

export default withProfile(PhoneOtpPage)
